import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import styled from "styled-components";
import HeaderUnderlined from "../components/HeaderUnderlined";
// import PlatformIcon from "../components/PlatformIcon";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  AiOutlineLink,
  AiOutlineSetting,
  AiOutlineThunderbolt,
} from "react-icons/ai";
import { FaBookmark } from "react-icons/fa";

const PortfolioTemplate = ({ data, pageContext }) => {
  const next = pageContext.next;
  const previous = pageContext.previous;

  const {
    title,
    body,
    relationships,
    field_link,
    field_image,
    field_picture,
    field_platform,
    field_new,
  } = data.allNodePortfolio.nodes[0];
  const thumbnail = getImage(
    relationships.field_image.localFile.childImageSharp
  );
  const pictures = relationships.field_picture;

  return (
    <>
      <Layout>
        <Seo title={title} />

        <Container fluid="lg">
          <Row>
            <Col xl={3} lg={3} md={4} sm={6} xs={12} className="wavebackground">
              <div>
                <GatsbyImage image={thumbnail} alt={field_image.alt} />
              </div>
            </Col>
            <Col className="p-5 bg-white">
              {field_new && <FaBookmarkStyled />}
              <HeaderUnderlined titlesrc={title} />
              <div className="pb-2">
                <span>
                  <AiOutlineLink className="react-icons mr-2" />
                </span>
                <span className="font-weight-bold">Ссылка: </span>
                <span>
                  <a
                    href={field_link.uri}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {field_link.uri}
                  </a>
                </span>
              </div>
              <div className="pb-2">
                <span>
                  <AiOutlineThunderbolt className="react-icons mr-2" />
                </span>
                <span className="font-weight-bold">Платформа: </span>
                <span className="text-capitalize">{field_platform}</span>
              </div>
              <Alert variant="success">
                Узнайте больше про{" "}
                <span className="text-capitalize">
                  <Link to={`/${field_platform}`}>
                    <Alert.Link as={Link}>{field_platform}</Alert.Link>
                  </Link>
                  {/* <PlatformIcon platformsrc={field_platform} /> */}
                </span>
              </Alert>

              <div className="pb-2">
                <span>
                  <AiOutlineSetting className="react-icons mr-2" />
                </span>
                <span className="font-weight-bold">
                  Инструменты разработки:{" "}
                </span>
                <div>
                  <ul>
                    {relationships.field_tags.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <Alert variant="info">
                <div
                  className="font-weight-bold"
                  dangerouslySetInnerHTML={{ __html: body.summary }}
                />
              </Alert>

              <hr />
              <div dangerouslySetInnerHTML={{ __html: body.processed }} />
              {pictures.map((picture, index) => {
                const pic = getImage(picture.localFile);
                return (
                  <div key={index} className="flex text-center">
                    <GatsbyImage image={pic} alt={field_picture[index].alt} />
                    <p className="text-center font-weight-bold">
                      {field_picture[index].alt}
                    </p>
                  </div>
                );
              })}
              <hr />

              <Container className="py-3">
                <Row>
                  <Col>
                    {previous && (
                      <Link
                        to={previous.path.alias}
                        rel="prev"
                        className="font-weight-bold"
                      >
                        <FaArrowLeft className="react-icons" /> {previous.title}
                      </Link>
                    )}
                  </Col>
                  <Col className="text-right">
                    {next && (
                      <Link
                        to={next.path.alias}
                        rel="next"
                        className="font-weight-bold"
                      >
                        {next.title} <FaArrowRight className="react-icons" />
                      </Link>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};
export const query = graphql`
  query ($porfolioPageId: String!) {
    allNodePortfolio(filter: { id: { eq: $porfolioPageId } }) {
      nodes {
        title
        path {
          alias
        }
        body {
          processed
          summary
        }
        field_new
        field_image {
          alt
        }
        field_platform
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          field_tags {
            name
            path {
              alias
            }
          }
          field_picture {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
        field_link {
          uri
          title
        }
        field_picture {
          width
          height
          title
          alt
        }
      }
    }
  }
`;
export default PortfolioTemplate;

const FaBookmarkStyled = styled(FaBookmark)`
  font-size: 3rem;
  position: absolute;
  right: 10px;
  top: -4px;
  z-index: 1;
  color: brown;
`;
